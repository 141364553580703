<template>
  <MainContent>
    <div class="container">
      <CreateKeywordForm />
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import CreateKeywordForm from '@/components/Modules/Keywords/CreateKeywordForm'
import { trackEvent } from '@/lib/analytics'

export default {
  name: 'CreateKeywordPage',
  components: {
    MainContent,
    CreateKeywordForm,
  },
  mounted() {
    trackEvent(this.$intercom, 'Create-Keyword')
  },
}
</script>
