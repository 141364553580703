<template>
  <b-sidebar
    lazy
    right
    shadow
    backdrop
    width="900px"
    v-model="shown"
    no-close-on-backdrop
    backdrop-variant="dark"
    id="auto-campaign-sidebar"
    :title="`${titleVerb} ${keywordName}`"
  >
    <div class="px-4">
      <b-card no-body class="active">
        <b-card-header header-tag="header" role="tab">
          <div class="col-auto ml-0 pl-0">
            <h2 class="mb-0">Write your message</h2>
          </div>
        </b-card-header>
        <b-card-body>
          <CreateMessageForm />
        </b-card-body>
      </b-card>
      <button
        class="btn btn-block btn-lg btn-primary"
        :disabled="!isFormValid"
        :class="{ 'is-loading': isLoading }"
        @click="createNewCampaign"
      >
        Save Campaign
      </button>
    </div>
  </b-sidebar>
</template>

<script>
import CreateCampaignStep from '@/components/Modules/Campaign/CreateCampaign/CreateCampaignStep'
import CreateCampaignSchedule from '@/components/Modules/Campaign/CreateCampaign/CreateCampaignSchedule'
import CreateCampaignType from '@/components/Modules/Campaign/CreateCampaign/CreateCampaignType'
import CreateCampaignAudience from '@/components/Modules/Campaign/CreateCampaign/CreateCampaignAudience'
import CreateCampaignSummary from '@/components/Modules/Campaign/CreateCampaign/CreateCampaignSummary'
import CreateMessageForm from '@/components/Modules/Campaign/CreateCampaign/CreateMessageForm'
import { validationRules } from '@/lib/campaign-validation'
import { createNamespacedHelpers } from 'vuex'

const tagModule = createNamespacedHelpers('tag')
const campaignModule = createNamespacedHelpers('campaign')

// @group Keywords
// This component is the sidebar modal that allows users to create custom resonses to associate with the Keyword.
export default {
  name: 'CreateKeywordCampaignSidebar',
  components: {
    CreateMessageForm,
  },
  data: () => ({
    keywordName: '',
    prevCampaignId: '',
    currentTagId: '',
    shown: false,
    isLoading: false,
    titleVerb: '',
  }),
  computed: {
    ...campaignModule.mapState(['campaign']),
    isFormValid() {
      return this.campaign.messageBody && !this.isLoading
    },
  },
  methods: {
    ...campaignModule.mapMutations(['resetCampaign', 'setCampaignToEdit']),
    ...tagModule.mapActions(['activateTagAction']),
    ...campaignModule.mapActions(['toggleCampaign', 'createCampaign']),
    // @vuese
    // Check if user is sure they want to archive the keyword
    // @arg keyword: `Object`, campaignid, verb: `Create`/`Edit` RETURNS: none
    toggleSidebar({ keyword, campaignId, verb }) {
      this.shown = !this.shown
      this.titleVerb = verb

      if (this.shown) {
        this.keywordName = `${keyword} - keyword custom response`
        if (campaignId) {
          this.setCampaignToEdit(campaignId)
        } else {
          this.resetCampaign()
        }
      }
    },
    async createNewCampaign() {
      this.shown = false
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .b-sidebar-header {
  .close {
    margin-right: 10px !important;
  }
  strong {
    text-transform: capitalize;
  }
}
.active {
  .card-header {
    color: #fff;
    border-color: #2c7be5;
    background-color: #2c7be5;
  }
}
</style>
