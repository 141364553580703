<template>
  <FadeTransition>
    <div class="container">
      <!-- Header -->
      <div class="header">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h1 class="header-title">{{ titleVerb }} Keyword</h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Form -->
      <div class="row">
        <form class="col-12">
          <!-- Keyword -->
          <div class="form-group mb-5">
            <label for="keyword mb-1" class="font-weight-bold">Keyword</label>
            <small class="form-text text-muted"
              >This is the keyword that customers will text in. (No spaces, letters and numbers
              only)</small
            >
            <input
              type="text"
              id="keyword"
              v-model="form.keyword"
              placeholder="BURGER5"
              class="form-control text-uppercase"
              :disabled="titleVerb == 'Edit'"
            />
          </div>
          <!-- Tags -->
          <div class="form-group mb-5">
            <label for="tags mb-1" class="font-weight-bold">Tag Customer</label>
            <small class="form-text text-muted"
              >When a customer texts in this number, we can automatically tag them so you can
              identify them later.</small
            >
            <div v-if="!tagExists">
              <input
                type="text"
                class="form-control"
                placeholder="Enter new tag or select checkbox below to select from existing tags"
                v-model="form.newTag"
              />
            </div>
            <div v-else>
              <MultiSelect
                id="tags"
                :allow-empty="false"
                :show-labels="false"
                placeholder="Select Tag"
                openDirection="below"
                v-model="form.existingTag"
                label="name"
                :options="tags"
              ></MultiSelect>
            </div>
            <div class="form-check mt-2 ml-1">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="tagExists"
                id="tagCheckbox"
              />
              <label class="form-check-label font-weight-bold" for="tagCheckbox"
                >Use existing tag?</label
              >
            </div>
          </div>
          <!-- Action -->
          <div class="form-group mb-3">
            <label for="action mb-1" class="font-weight-bold"
              >What action will this keyword trigger?</label
            >
            <ActionCards
              :keyword="form"
              :keywordToEdit="keywordToEdit"
              :verb="titleVerb"
              @setAction="form.action = $event"
              @toggleSidebar="toggleSidebar()"
            />
          </div>
          <!-- Description -->
          <div class="form-group mb-5">
            <label for="description mb-1" class="font-weight-bold"
              >Keyword Details (Optional)</label
            >
            <textarea
              v-model="form.description"
              class="form-control"
              id="description"
              maxlength="50"
              placeholder="Only one per customer...."
              rows="2"
            ></textarea>
          </div>
          <!-- Buttons -->
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-primary btn-block"
              :disabled="invalidInputs"
              @click.prevent="onSave()"
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <CreateKeywordCampaignSidebar ref="campaignSidebar" />
    </div>
  </FadeTransition>
</template>

<script>
import FadeTransition from '@/components/Transitions/FadeTransition'
import { createNamespacedHelpers } from 'vuex'
import MultiSelect from '@/components/Common/MultiSelect'
import CreateKeywordCampaignSidebar from './CreateKeywordCampaignSidebar'
import CustomerTags from '@/components/Modules/Customer/CustomersTableCard/CustomersTableUtil/CustomerTags'
import ActionCards from './CreateKeywordActionCards'
import chrono from 'chrono-node'
import { cloneDeep } from 'lodash-es'

const tagModule = createNamespacedHelpers('tag')
const keywordModule = createNamespacedHelpers('keyword')
const campaignModule = createNamespacedHelpers('campaign')
const customerModule = createNamespacedHelpers('customer')

// @group Keywords
// This component is the main create/edit form page
export default {
  name: 'CreateKeywordForm',
  components: {
    FadeTransition,
    MultiSelect,
    CustomerTags,
    CreateKeywordCampaignSidebar,
    ActionCards,
  },
  data: () => ({
    form: {
      keyword: '',
      description: '',
      action: '',
      newTag: '',
      existingTag: '',
    },
    keywordPayloadFields: {},
    customer: { _id: '' },
    tagExists: false,
    titleVerb: 'Create',
    newTag: null,
  }),
  mounted() {
    this.getTags()
    if (Object.keys(this.keywordToEdit).length > 0) {
      this.setupEdit(this.keywordToEdit)
      this.queryCampaigns()
    }
    if (this.keywordList.length == 0) {
      // fixes case of refreshing page
      this.getKeywords()
    }
  },
  computed: {
    ...campaignModule.mapState(['campaign']),
    ...tagModule.mapGetters({ tags: 'externalTags' }),
    ...keywordModule.mapGetters({ keywordToEdit: 'keywordToEdit', keywordList: 'keywords' }),
    ...customerModule.mapState({ campaignFilters: 'listFilters' }),
    invalidInputs() {
      let form = this.form
      if (form.keyword == '' || form.action == '' || form.tag == '') return true
      else return false
    },
    keywordNameIsUnique() {
      return this.keywordList.find(({ word }) => word === this.form.keyword) ? false : true
    },
    keywordNameAndTagWasInput() {
      if (this.form.keyword != '' && (this.form.newTag != '' || this.form.existingTag != ''))
        return true
      else return false
    },
  },
  methods: {
    ...tagModule.mapActions(['getTags', 'addTag']),
    ...keywordModule.mapMutations(['setKeywordToEdit']),
    ...keywordModule.mapActions(['addKeyword', 'updateKeyword', 'getKeywords']),
    ...campaignModule.mapMutations(['resetCampaign']),
    ...campaignModule.mapMutations({
      setCampaignTitle: 'setTitle',
      setCampaignType: 'setType',
      setCampaignFilters: 'setFilters',
    }),
    ...campaignModule.mapActions([
      'toggleCampaign',
      'createCampaign',
      'queryCampaigns',
      'updateCampaign',
    ]),
    // @vuese
    // Calls other functions to validate, create/add, and save tags, campaigns and the keyword.
    async onSave() {
      // set keyword to lowercase and then check for possibility of invalid keyword conditions
      this.form.keyword = this.form.keyword.toLowerCase()
      if (!this.keywordIsValid()) {
        return
      }

      // seed keywordPayloadFields
      this.keywordPayloadFields.action = this.form.action
      this.keywordPayloadFields.word = this.form.keyword.toLowerCase()
      this.keywordPayloadFields.description = this.form.description
      this.keywordPayloadFields.tagId = ''

      // check tag to see if it's new or existing, and create new tag if needed
      if (!(await this.linkTag())) {
        return
      }

      // Create campaign if 'custom response' is selected and no campaign info exists
      // ELSE Edit campaign if 'custom response' is selected and campaign info exists
      // ELSE Disable previous campaign if user switched action away from 'custom response'
      if (this.form.action == 'custom response' && !this.keywordToEdit.campaign) {
        if (!(await this.linkCampaign())) {
          return
        }
      } else if (this.form.action == 'custom response' && this.keywordToEdit.campaign) {
        // reactivate if previously paused
        if (this.campaign.status == 'paused') {
          if (!(await this.togglePreviousCampaign())) {
            return
          }
        }
        if (!(await this.updateCurrentCampaign())) {
          return
        }
      } else if (
        this.form.action != 'custom response' &&
        this.keywordToEdit.action == 'custom response'
      ) {
        if (!(await this.togglePreviousCampaign())) {
          return
        }
      }

      // Add/Update keyword
      if (!(await this.saveKeyword())) {
        return
      }
      this.$router.push('keywords')
    },
    // @vuese
    // Saves the finalized keyword
    // @arg Params: none, RETURNS: `true`/`false`
    async saveKeyword() {
      try {
        if (this.titleVerb == 'Create') {
          this.addKeyword(this.keywordPayloadFields)
        } else {
          this.updateKeyword({
            keywordId: this.keywordToEdit._id,
            fields: this.keywordPayloadFields,
          })
        }
        this.$notify({ text: 'Successfully saved keyword.' })
        return true
      } catch {
        this.$notify({
          type: 'error',
          text: (error.data && error.data.message) || 'Saving keyword failed!',
        })
        return false
      }
    },
    // @vuese
    // Updates an existing campaign
    // @arg Params: none, RETURNS: `true`/`false`
    async updateCurrentCampaign() {
      let campaign = this.parseCampaign(this.campaign)
      if (!campaign.title || !campaign.messageBody) {
        return true
      }
      try {
        await this.updateCampaign({
          campaignId: campaign._id,
          fields: {
            title: campaign.title,
            messageBody: campaign.messageBody,
            messageMedia: campaign.messageMedia,
            maxMessagesPerDay: campaign.maxMessagesPerDay,
            promo: campaign.promo || null,
            status: campaign.status,
            'filters.surveyRatings': campaign.filters.surveyRatings,
            'filters.locations': campaign.filters.locations,
            'filters.dateSettings': { ...campaign.filters.dateSettings },
            'filters.actions': campaign.filters.actions,
            'filters.tags': campaign.filters.tags,
            scheduling: {
              delay: campaign.scheduling.delay,
              delayLength: campaign.scheduling.delayLength,
              delayUnit: campaign.scheduling.delayUnit,
              autoEnd: campaign.scheduling.autoEnd,
              autoEndDate: campaign.scheduling.autoEndDate,
              blastScheduleDate: campaign.scheduling.blastScheduleDate,
              blastSchedule: campaign.scheduling.blastSchedule,
            },
          },
        })
        this.$notify({ text: `Successfully updated campaign` })
        return true
      } catch (error) {
        console.log(error)
        this.$notify({ type: 'error', text: 'Campaign failed to update' })
        return false
      }
    },
    // @vuese
    // Creates a new campaign
    // @arg Params: none, RETURNS: `true`/`false`
    async linkCampaign() {
      this.setCampaignTitle(`${this.keywordPayloadFields.word} - keyword custom response`)
      this.setCampaignType('tag')
      this.setCampaignFilters({
        ...this.campaignFilters,
        tags: [
          {
            id: this.keywordPayloadFields.tagId,
            name: this.form.existingTag != '' ? this.form.existingTag.name : this.newTag.name,
          },
        ],
        dateSettings: {
          name: 'Since',
          type: 'since',
          date: this.$moment().format('MMM Do, YYYY'),
        },
      })
      try {
        // create a campaign and add campaignId to keywordfields
        this.keywordPayloadFields.campaignId = await this.createCampaign(this.campaign)
        this.$notify({ text: `Successfully saved campaign` })
        return true
      } catch (error) {
        console.log(error)
        this.$notify({ type: 'error', text: 'Campaign failed to be saved' })
        return false
      }
    },
    // @vuese
    // Activates or pauses and existing associated campaign
    // @arg Params: none, RETURNS: `true`/`false`
    async togglePreviousCampaign() {
      try {
        await this.toggleCampaign(this.keywordToEdit.campaign._id)
        this.$notify({ text: `Successfully toggled campaign` })
        return true
      } catch (error) {
        console.log(error)
        this.$notify({ type: 'error', text: 'Failed to toggle campaign' })
        return false
      }
    },
    async linkTag() {
      if (this.tagExists) {
        this.keywordPayloadFields.tagId = this.form.existingTag._id
      } else {
        try {
          this.newTag = await this.addTag(this.form.newTag)
          this.keywordPayloadFields.tagId = this.newTag._id
          this.$notify({ text: 'Successfully created new tag.' })
        } catch (error) {
          this.$notify({
            type: 'error',
            text: (error.data && error.data.message) || 'Adding new tag failed!',
          })
          return false
        }
      }
      return true
    },
    // @vuese
    // Helper function for updateCurrentCampaign()
    // @arg Params: campaign `Object`, RETURNS: `Object` new parsed campaign
    parseCampaign(campaign) {
      const parsedCampaign = cloneDeep(campaign)
      if (parsedCampaign.scheduling) {
        if (parsedCampaign.scheduling.blastScheduleDate) {
          parsedCampaign.scheduling.blastScheduleDate = chrono.parseDate(
            parsedCampaign.scheduling.blastScheduleDate
          )
        }
      }
      return parsedCampaign
    },
    // @vuese
    // Validator function for keyword names
    // @arg Params: none, RETURNS: `true`/`false`
    keywordIsValid() {
      if (!this.isValidKeywordName(this.form.keyword)) {
        this.$notify({ type: 'error', text: 'Invalid keyword name' })
        return false
      }
      if (!this.keywordNameIsUnique && this.titleVerb == 'Create') {
        this.$notify({ type: 'error', text: 'Keyword name must be unique' })
        return false
      }
      return true
    },
    // @vuese
    // Populates the form with keyword data for editing
    // @arg Params: keyword `Object`, RETURNS: none
    setupEdit(keyword) {
      this.titleVerb = 'Edit'
      this.tagExists = true
      this.form.keyword = keyword.word
      this.form.description = keyword.description
      this.form.action = keyword.action
      this.form.newTag = ''
      this.form.existingTag = keyword.tag
    },
    // @vuese
    // Helper function for keywordIsValid(). Regex Validation
    // @arg Params: `String` name of keyword, RETURNS: `true`/`false`
    isValidKeywordName(name) {
      return /^[0-9a-z]+$/.test(name.trim())
    },
    // @vuese
    // Opens the campaign sidebar and populates if editing.
    // @arg Params: none, RETURNS: none
    toggleSidebar() {
      try {
        this.$refs.campaignSidebar.toggleSidebar({
          keyword: this.form.keyword,
          campaignId: this.keywordToEdit.campaign ? this.keywordToEdit.campaign._id : null,
          verb: this.titleVerb,
        })
      } catch (error) {
        console.error(error)
        this.$notify({
          type: 'error',
          title: `activating action failed!`,
        })
      }
    },
  },
  destroyed() {
    // clear out the state so previously edited words don't show again
    this.setKeywordToEdit({})
    this.resetCampaign()
  },
}
</script>
