<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group mb-0">
        <div class="row">
          <div class="col-12 col-lg-6 dropdown">
            <!-- Pre-defined actions card -->
            <div class="card" :class="{ active: isExistingAction }" data-toggle="dropdown">
              <div class="dropdown-menu dropdown-menu-right">
                <a
                  href="#"
                  class="dropdown-item"
                  v-for="(action, index) in actionOptions"
                  :key="index"
                  @click="setAction(action)"
                >
                  {{ action }}
                </a>
              </div>
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <a href="#!" class="avatar">
                      <span class="avatar-title rounded-circle">
                        <i class="fas fa-list"></i>
                      </span>
                    </a>
                  </div>
                  <div class="col">
                    <!-- Title -->
                    <h4 class="card-title mb-1">
                      <a href="#!" class="text-capitalize">{{ actionText }}</a>
                    </h4>
                    <!-- Status -->
                    <p class="card-text text-muted small">Click to select an action.</p>
                  </div>
                  <div class="col-auto" v-if="isExistingAction">
                    <a href="#!" class="btn btn-sm btn-primary btn-rounded-circle">
                      <span class="fe fe-check"></span>
                    </a>
                  </div>
                </div>
                <!-- / .row -->
              </div>
              <!-- / .card-body -->
            </div>
          </div>

          <!-- Custom response card -->
          <div class="col-12 col-lg-6">
            <div
              class="card"
              :class="{ active: keyword.action == 'custom response' }"
              @click="setAction('custom response')"
            >
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <!-- Avatar -->
                    <a href="#!" class="avatar">
                      <span class="avatar-title rounded-circle text">
                        <i class="fas fa-edit pl-2"></i>
                      </span>
                    </a>
                  </div>
                  <div class="col">
                    <!-- Title -->
                    <h4 class="card-title mb-1">
                      <a href="#!">{{ customVerb }} Custom Response</a>
                    </h4>

                    <!-- Status -->
                    <p class="card-text text-muted small">Click to create or edit existing.</p>
                  </div>

                  <div class="col-auto">
                    <a
                      href="#!"
                      class="btn btn-sm btn-primary btn-rounded-circle"
                      v-if="keyword.action == 'custom response'"
                    >
                      <span class="fe fe-check"></span>
                    </a>
                  </div>
                </div>
                <!-- / .row -->
              </div>
              <!-- / .card-body -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const campaignModule = createNamespacedHelpers('campaign')
// @group Keywords
// This component displays the action cards in the create/edit keyword form
export default {
  name: 'CreateKeywordActionCards',
  props: {
    // The keyword form object currently being manipulated
    keyword: {
      type: Object,
      required: true,
    },
    // The title verb of the parent.
    verb: {
      // `'Edit'`/`'Create'`
      type: String,
      required: true,
    },
    // Pre-existing keyword that serves as a reference for editing
    keywordToEdit: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    actionOptions: [
      'no action',
      'send survey invite',
      'send order link',
      'send menu link',
      'marketing opt-in',
    ],
    action: '',
  }),
  computed: {
    ...campaignModule.mapState(['campaign']),
    isExistingAction() {
      return /no action|send survey invite|send order link|send menu link|marketing opt-in|marketing opt-out/i.test(
        this.keyword.action
      )
    },
    actionText() {
      if (
        (this.verb == 'Create' && !this.keyword.action) ||
        (this.verb == 'Edit' && this.keyword.action == 'custom response')
      ) {
        return 'Select Existing Action'
      } else return this.keyword.action
    },
    customVerb() {
      if (this.verb == 'Edit' && this.keywordToEdit.campaign) {
        return 'Edit'
      } else {
        return 'Create'
      }
    },
  },
  methods: {
    // @vuese
    // Emits various events based of selected action
    // @arg `String` the selected action. RETURNS: none
    setAction(action) {
      if (action == 'custom response') {
        // Notifies parent that an action was selected.
        // @arg `String` The selected action
        this.$emit('setAction', action)
        // Notifies parent to open the campaign sidebar.
        this.$emit('toggleSidebar')
      } else {
        this.action = action
        this.$emit('setAction', action)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  cursor: pointer;
}
.active {
  border-color: var(--primary);
}
</style>
